<template>
  <div>
     <v-card class="mx-auto card flat-card" color="surface">
      <v-card-text class="pa-0">
        <v-row class="accent header mx-3">
          <v-col cols="3" class="d-flex justify-center"><span class="font-weight-black">تاریخ</span></v-col>
          <v-col cols="3" class="d-flex justify-center"><span class="font-weight-black">دریافتی<small>(ریال)</small></span></v-col>
          <v-col cols="3" class="d-flex justify-center"><span class="font-weight-black">پرداختی<small>(ریال)</small></span></v-col>
          <v-col cols="3" class="d-flex justify-center"><span class="font-weight-black">مانده<small>(ریال)</small></span></v-col>
        </v-row>
        <div class="pt-13">
        <v-row class="mb-2 mx-1" v-for="info in purseInfo" :key="info.id">
          <v-col cols="3" class="d-flex justify-center pa-2"><span class="medium-font onSurface--text" v-text="info.date"></span></v-col>
          <v-col cols="3" class="d-flex justify-center pa-2"><span class="medium-font onSurface--text" v-text="info.income"></span></v-col>
          <v-col cols="3" class="d-flex justify-center pa-2"><span class="medium-font onSurface--text" v-text="info.outgoing"></span></v-col>
          <v-col cols="3" class="d-flex justify-center pa-2"><span class="medium-font onSurface--text" v-text="info.balance"></span></v-col>
        </v-row>
        </div>
       </v-card-text>
     </v-card>
    
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      purseInfo: state => state.purse.purse_info
    })
  }
}
</script>
