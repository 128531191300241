<template>
<v-container>
  <div>
   <navbar title="تراکنش ها"/>
   <v-main class="pt-15">
       <transaction/>
   </v-main>
  </div>
  </v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import transaction from './components/cards/transactionsCard'
import { PurseView } from './models/purse'

export default {
  props: ['purseID'],
  components: {
    navbar,
    transaction
  },
  created () {
    PurseView(this.$props.purseID)
  },
}
</script>